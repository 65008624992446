import React from 'react';
import { graphql} from 'gatsby';
import PrivacyPolicy from '../../templates/multilanguage/PrivacyPolicy';

export default ({ data }) => {
  return <PrivacyPolicy data={data} />;
};

export const query = graphql`
 query PrivacyPolicyRo {
  mdx(frontmatter: {type: {eq: "privacy-policy"} language: {eq: "pl"}}) {
      id
      body
      fields {
        slug
        canonical
        lang
        relatedPages {
          en {
            url
            country
          }
          it {
            url
            country
          }
          ro {
            url
            country
          }
          es {
            url
            country
          }
          fr {
            url
            country
          }
          pl {
            url
            country
          }
          de {
            url
            country
          }
          cz {
            url
            country
          }
        }
      }
      frontmatter {
        type
        title
        metaTitle
        metaDescription
        metaImage {
          publicURL
        }
        metaCover {
          publicURL
        }
        metaKeywords
        twitterCard
        ldJson
      }
  }
}
`
